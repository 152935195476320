export class MessagingIframe<IncomingMessage, OutgoingMessage> {
  public element: HTMLIFrameElement;
  private pageUrl: string;
  private onMessageCallback: (msg: IncomingMessage) => void;
  private messageListener: (e: MessageEvent) => void;

  constructor(pageUrl: string, onMessageCallback: (msg: IncomingMessage) => void) {
    this.pageUrl = pageUrl;
    this.onMessageCallback = onMessageCallback;

    this.element = document.createElement('iframe');
    this.element.setAttribute('src', pageUrl);
    this.element.setAttribute('sandbox', 'allow-popups allow-scripts allow-same-origin');
    this.element.style.display = 'none';

    this.setupPostMessageHandling();

    document.body.append(this.element);
  }

  private setupPostMessageHandling() {
    this.messageListener = (e: MessageEvent) => {
      if (e.source !== this.element.contentWindow) {
        console.debug("postMessage from unrecognized source", e)
        return;
      }
      this.onMessageCallback(e.data);
    };
    window.addEventListener('message', this.messageListener);
  }

  public sendMessage(message: OutgoingMessage) {
    this.element.contentWindow.postMessage(message, this.pageUrl);
  }

  close() {
    window.removeEventListener('message', this.messageListener);
    this.element.remove();
  }
}