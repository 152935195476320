export class IframeInternalListener<IncomingMessage> {
  private onMessageCallback: (msg: IncomingMessage) => void;
  private listener: (e: MessageEvent) => void;

  constructor(onMessageCallback: (msg: IncomingMessage) => void) {
    this.onMessageCallback = onMessageCallback;
    this.listener = (e: MessageEvent) => {
      if (e.source !== window.parent) {
        console.log("postMessage from non-parent")
        return;
      }
      this.onMessageCallback(e.data);
    };
    window.addEventListener("message", this.listener, false);
  }

  public close() {
    window.removeEventListener("message", this.listener, false);
  }
}