import {
  AlwaysLoadScriptOffer,
  GlobalToLocalPostMessage,
  LocalToGlobalPostMessage,
  StoredScriptDefinition
} from "../postMessages";
import { ScriptStorage } from "./ScriptStorage";
import { IframeInternalListener } from "@meta-web/messaging-iframe-helper";
import { ScriptDefinitionV0 } from "@meta-web/scripts-v0";

export class GlobalLauncher {
  private iframeInternalListener: IframeInternalListener<LocalToGlobalPostMessage>;
  private hostLocation: string;
  private storage: ScriptStorage;

  // Contains a random key to a confirmation of loading a given script on each page load
  private alwaysLoadOffers = new Map<string, string>();

  static init(hostLocation: string) {
    const globalLauncher = new GlobalLauncher(hostLocation);
    globalLauncher.sendInitialState();
    return globalLauncher;
  }

  private constructor(hostLocation: string) {
    this.hostLocation = hostLocation;
    this.iframeInternalListener = new IframeInternalListener((msg: LocalToGlobalPostMessage) => {
      this.handleMessage(msg);
    });
    this.storage = new ScriptStorage(this.hostLocation);
  }

  private sendInitialState() {
    const storedScripts = this.storage.getStoredScripts();
    const alwaysLoadOffers: Array<AlwaysLoadScriptOffer> = [];
    for (const storedScript of storedScripts.scripts) {
      if (storedScript.startupLoadOffer) {
        // These will be sent for every script - even if it has already been accepted - this is to allow a user rejecting
        // and then accepting in a single session
        const key = btoa(window.crypto.getRandomValues(new Uint8Array(16)) as unknown as string);
        this.alwaysLoadOffers.set(key, storedScript.url);
        alwaysLoadOffers.push({
          definition: storedScript,
          key: key,
        });
      }
    }
    if (alwaysLoadOffers.length > 0) {
      this.sendMessage({
        alwaysLoadOffers: {
          offers: alwaysLoadOffers,
        }
      })
    }
    this.sendMessage({
      currentScripts: this.storage.getStoredScripts(),
    });
    this.sendMessage({
      enabledScripts: this.storage.getEnabledScripts(),
    });
  }

  handleMessage(message: LocalToGlobalPostMessage) {
    if (message.loadedScript) {
      this.handleLoadedScript(message.loadedScript);
    }
    if (message.storeScript) {
      this.handleStoreScript(message.storeScript);
    }
    if (message.alwaysLoadConfirmation) {
      this.handleAlwaysLoadConfirmation(message.alwaysLoadConfirmation);
    }
    if (message.alwaysLoadRejection) {
      this.handleAlwaysLoadRejection(message.alwaysLoadRejection);
    }
    if (message.unloadedScript) {
      this.handleUnloadedScript(message.unloadedScript);
    }
    if (message.removeScript) {
      this.handleRemovedScript(message.removeScript);
    }
  }

  private sendMessage(message: GlobalToLocalPostMessage) {
    console.log("global.sendMessage", message);
    window.parent.postMessage(message, "*");
  }

  private handleLoadedScript(loadedScript: { definition: StoredScriptDefinition }) {
    console.log("GLOBAL message.loadedScript", loadedScript);
    const enabledScripts = this.storage.getEnabledScripts();
    for (const script of enabledScripts.scripts) {
      if (script.url === loadedScript.definition.url) {
        console.warn("Script already enabled - ignoring");
        return;
      }
    }
    enabledScripts.scripts.push({
      name: loadedScript.definition.name,
      url: loadedScript.definition.url,
    });
    this.storage.persistEnabledScripts(enabledScripts);
  }

  private handleAlwaysLoadRejection(alwaysLoadRejection: { key: string }) {
    const scriptUrl = this.alwaysLoadOffers.get(alwaysLoadRejection.key);
    if (!scriptUrl) {
      console.error("Missing always load rejection key received - clearing the map to prevent misuse");
      this.alwaysLoadOffers.clear();
      return;
    }

    const storedScripts = this.storage.getStoredScripts();
    for (const script of storedScripts.scripts) {
      if (script.url === scriptUrl && script.startupLoadOffer) {
        script.startupLoadOfferRejected = true;
      }
    }
    this.storage.persistStoredScripts(storedScripts);
  }

  private handleAlwaysLoadConfirmation(alwaysLoadConfirmation: { key: string }) {
    const scriptUrl = this.alwaysLoadOffers.get(alwaysLoadConfirmation.key);
    if (!scriptUrl) {
      console.error("Missing always load confirmation key received - clearing the map to prevent misuse");
      this.alwaysLoadOffers.clear();
      return;
    }

    const storedScripts = this.storage.getStoredScripts();
    for (const script of storedScripts.scripts) {
      if (script.url === scriptUrl && script.startupLoadOffer) {
        script.startupLoadOfferAccepted = true;
      }
    }
    this.storage.persistStoredScripts(storedScripts);
  }

  private handleStoreScript(storeScript: { definition: StoredScriptDefinition }) {
    // Store that this script exists so that it can be used on other hosts
    const storedScripts = this.storage.getStoredScripts();
    for (const script of storedScripts.scripts) {
      if (script.url === storeScript.definition.url) {
        return;
      }
    }
    const {url, name, startupLoadOffer} = storeScript.definition;
    const toStore: ScriptDefinitionV0 = {
      name: name,
      url: url,
      startupLoadOffer: startupLoadOffer ? {
        description: startupLoadOffer.description,
      } : undefined,
    }
    storedScripts.scripts.push(toStore);
    this.storage.persistStoredScripts(storedScripts);
  }

  private handleUnloadedScript(unloadedScript: { scriptUrl: string }) {
    const enabledScripts = this.storage.getEnabledScripts();
    let scriptIndex = -1;
    for (let i = 0; i < enabledScripts.scripts.length; i++) {
      const script = enabledScripts.scripts[i];
      if (script.url === unloadedScript.scriptUrl) {
        scriptIndex = i;
        break;
      }
    }
    if (scriptIndex === -1) {
      console.warn("Script not found");
      return;
    }
    enabledScripts.scripts.splice(scriptIndex, 1);
    this.storage.persistEnabledScripts(enabledScripts);
  }

  private handleRemovedScript(removedScript: { scriptUrl: string }) {
    this.handleUnloadedScript(removedScript);

    const storedScripts = this.storage.getStoredScripts();
    let scriptIndex = -1;
    for (let i = 0; i < storedScripts.scripts.length; i++) {
      const script = storedScripts.scripts[i];
      if (script.url === removedScript.scriptUrl) {
        scriptIndex = i;
        break;
      }
    }
    if (scriptIndex === -1) {
      console.warn("Script not found");
      return;
    }
    storedScripts.scripts.splice(scriptIndex, 1);
    this.storage.persistStoredScripts(storedScripts);
  }
}
