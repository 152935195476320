import { EnabledScriptsStorage, ScriptLibraryStorage } from "../postMessages";

const localStorageScriptsKey = "modules";

export class ScriptStorage {
  private hostLocation: string;

  constructor(hostLocation: string) {
    this.hostLocation = hostLocation;
  }

  private getLocationScriptKey() {
    return `hostModules:${this.hostLocation}`;
  }

  public persistEnabledScripts(enabledScriptsStorage: EnabledScriptsStorage) {
    const serialised = JSON.stringify(enabledScriptsStorage);
    localStorage.setItem(this.getLocationScriptKey(), serialised);
  }

  public getEnabledScripts(): EnabledScriptsStorage {
    const currentStorage = localStorage.getItem(this.getLocationScriptKey());
    if (currentStorage === null) {
      return {scripts: []};
    }

    const parsed = JSON.parse(currentStorage);
    if (parsed.modules) {
      parsed.scripts = parsed.modules;
    }
    // TODO - validation
    return parsed;
  }

  public persistStoredScripts(scriptLibraryStorage: ScriptLibraryStorage) {
    const serialised = JSON.stringify(scriptLibraryStorage);
    localStorage.setItem(localStorageScriptsKey, serialised);
  }

  public getStoredScripts(): ScriptLibraryStorage {
    const currentStorage = localStorage.getItem(localStorageScriptsKey);
    if (currentStorage === null) {
      return {scripts: []};
    }

    const parsed = JSON.parse(currentStorage);
    if (parsed.modules) {
      parsed.scripts = parsed.modules;
    }
    // TODO - validation
    return parsed;
  }

}